<template>
	<div>
		<div class='a-comparison'>
			<div class="app-container a-comparison-container">
				<div class="a-comparison-header">
					<div class="row align-items-center">
						<div class='col-auto a-comparison-back'>
							<div class='a-comparison-back-text' @click='backPage'>
								<v-icon icon='arrow-prev' size='10' />
								{{$t('account.comparison.back_to_search')}}
							</div>
						</div>
						<div class="col">
							<p class='a-heading-1'>{{$t('account.comparison.title.hotels')}} {{comparison.count ? `(${comparison.count} ${$t('account.with')} ${comparison.maxCount})` : ""}}</p>
						</div>
						<a-comparison-action :filename="`${$t('account.comparison.title.hotels')} ${comparison.count ? `(${comparison.count} ${$t('account.with')} ${comparison.maxCount})` : ''}`" :link="copyLink">
							<template v-slot:share>
								<section class="pdf-item">
									<p class='a-pdf-text'>{{$t('account.trips.prices_are_for_reference_only')}} <span class='a-pdf-date'>{{$moment().format("DD/MM/YYYY HH:MM")}}</span></p>
									<p class='a-pdf-title'>{{$t('account.comparison.title.hotels')}} {{comparison.count ? `(${comparison.count} ${$t('account.with')} ${comparison.maxCount})` : ""}}</p>
									<p class='a-pdf-sub-title'>
										{{$moment(comparison.searchData.dateFrom).format("DD/MM/YYYY")}} - {{$moment(comparison.searchData.dateTo).format("DD/MM/YYYY")}}
									</p>
								</section>

								<section class='pdf-item' v-for='(item, i) in items' :key='i'>
									<div class="a-timeline-wrap">
										<a-card-hotels
											:item='item'
											:searchData='comparison.searchData'
										/>
									</div>
								</section>
							</template>
						</a-comparison-action>
					</div>
				</div>

				<template v-if='items.length > 0'>
					<div v-for='(item, i) in items' :key='i'>
						<trips-card
							:item='item'
							:check_availability='check_availability'
							@update-comparison='check_availability = $event, comparison = $event'
							:items_favourite='items_favourite'
							@update-favourite='hotelFavorite'
						/>
					</div>
				</template>

				<template v-else>
					<trips-preloader
						name='hotels'
						:title='$t("account.search.preloader.hotels.no_found")'
					/>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
	import TripsPreloader from '@/views/account/search/components/preloader';
	import TripsCard from '@/views/account/search/hotels/card';
	import { getComparison, hotelFavorite, comparisonCheckAvailability } from '@/services/request';
	import AComparisonAction from '@/views/account/comparison/components/action';
	import ACardHotels from '@/views/account/comparison/pdf-card/hotels';

	export default {
		data: () => ({
			comparison: {},
			check_availability: {},
			items_favourite: []
		}),
		components: {
			TripsPreloader,
			TripsCard,
			AComparisonAction,
			ACardHotels
		},
		computed: {
			items() {
				return this.comparison.items ? Object.values(this.comparison.items) : [];
			},
			copyLink() {
				return location.origin + '/share-hotel/' + this.$route.params.comparisonId
			}
		},
		created() {
			this.hotelFavorite();
			this.comparisonCheckAvailability();
			this.getComparison();
		},
		methods: {
			hotelFavorite() {
				hotelFavorite().then(res => this.items_favourite = res.data);
			},
			comparisonCheckAvailability() {
				comparisonCheckAvailability({
					type: 'hotel',
					orderId: this.$route.query.orderId,
					storageId: this.$route.query.storageId
				}).then(res => this.check_availability = res.data ?? {});
			},
			getComparison() {
				getComparison(this.$route.params.comparisonId).then(res => this.comparison = res.data ?? {});
			},
			backPage() {
				this.$router.push({
					name: "hotels-search",
					params: {orderId: this.comparison.orderId, storageId: this.comparison.storageId, lang: this.$route.params.lang},
					query: {source: this.$route.query.source}
				})
			}
		}
	}
</script>
