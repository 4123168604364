<template>
	<div class="a-timeline-hotels">
		<div class='a-timeline-item'>
			<div class='a-timeline-hotels-wrap'>
				<div class='a-timeline-hotels-content' style='margin-left: 0'>
					<ul class='a-timeline-hotels-stars-list'>
						<li class='a-timeline-hotels-stars-item' v-for='(item, i) in item.categorySimpleCode' :key='i'>
							<v-icon icon='star' size='10' />
						</li>
					</ul>
					<p class='a-timeline-hotels-title'>{{item.name}}</p>

					<div class="a-timeline-hotels-info">
						<p class='a-text-with-icon'>
							<v-icon icon='pin' size='13' />
							<span>{{item.address ? `${item.address}, `: null}}{{item.postalCode}} {{item.countryName}}</span>
						</p>
					</div>

					<div class='row a-timeline-hotels-rooms' style='align-items: center; margin-top: 15px;'>
						<div class='col-auto pr-0'>
							<a-card-list
								:items="[{icon: 'users', name: searchData.adults + searchData.children.length}]"
							/>
						</div>
						<div class='col-auto'>
							<p class='a-timeline-hotels-rooms-text mb-0'>{{$_.minBy(item.offers, 'amount').roomName}}</p>
							<p class='a-timeline-hotels-rooms-text mb-0'>{{$_.minBy(item.offers, 'amount').boardName}}</p>
						</div>
					</div>
				</div>
			</div>
			<div class="divider mb-4"></div>
			<div class='a-timeline-hotels-footer'>
				<div class='row'>
					<div class='col-auto'>
						<p class='a-timeline-hotels-descr'>{{$t('account.form.placeholder.dateFrom')}}</p>
						<p class='a-timeline-hotels-text' v-if='item.searchData'>{{$moment(item.searchData.dateFrom).format("ddd, DD MMM YYYY")}}</p>
					</div>
					<div class='col-auto'>
						<p class='a-timeline-hotels-descr'>{{$t('account.form.placeholder.dateTo')}}</p>
						<p class='a-timeline-hotels-text' v-if='item.searchData'>{{$moment(item.searchData.dateTo).format("ddd, DD MMM YYYY")}}</p>
					</div>
				</div>
			</div>
		</div>
		<a-timeline-markup
			:item='item'
			:price="$helpers.amount($_.minBy(item.offers, 'amount').amount, $_.minBy(item.offers, 'amount').currency)"
			:result='true'
			:pdf='true'
		/>
	</div>
</template>

<script>
	import ATimelineMarkup from '@/views/account/itinerary/markup';
	import ACardList from '@/views/account/search/cars/card/list';

	export default {
		props: ['item', 'searchData'],
		components: {
			ATimelineMarkup,
			ACardList
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-timeline-hotels {
		&-wrap {
			@include minw($grid-breakpoints-xs) {
				padding: 20px * .8;
				display: flex;
				align-items: stretch;
			}
			@include minw($grid-breakpoints-lg) {
				padding: 20px;
			}
		}
		&-image {
			flex-shrink: 0;
			width: 100%;
			height: 170px;
			border-radius: 5px 5px 0 0;
			overflow: hidden;
			@include minw($grid-breakpoints-xs) {
				width: 225px;
				height: 140px;
				border-radius: 5px;
			}
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		&-content {
			padding: 15px;
			margin: 0;
			flex: 1 1 100%;
			@include minw($grid-breakpoints-xs) {
				padding: 0;
				margin: 5px * .8 0 0 25px * .8;
			}
			@include minw($grid-breakpoints-lg) {
				margin: 5px 0 0 25px;
			}
		}
		&-stars {
			&-list {
				display: flex;
				margin-bottom: 10px * .8;
				@include minw($grid-breakpoints-lg) {
					margin-bottom: 10px;
				}
			}
			&-item {
				margin: 0 1px;
				color: rgba($cyprus, .5);
			}
		}
		&-title {
			font-size: 22px * .8;
			line-height: 23px * .8;
			font-weight: 700;
			margin-bottom: 15px * .8;
			@include minw($grid-breakpoints-md) {
				font-size: 22px * .9;
				line-height: 23px * .9;
			}
			@include minw($grid-breakpoints-lg) {
				font-size: 22px;
				line-height: 23px;
				margin-bottom: 15px;
			}
		}
		&-dates {
			margin: 20px 0;
		}
		&-rooms {
			flex-direction: column;
			@include minw($grid-breakpoints-xs) {
				margin-top: 25px;
				flex-direction: row;
				align-items: flex-end;
			}
			&-text {
				font-size: 13px;
				font-weight: 400;
				margin-bottom: 5px;
			}
			&-group {
				display: flex;
				align-items: center;
				color: $green-persian;
				.app-icon {
					margin-right: 10px;
					margin-bottom: 5px;
				}
			}
		}
		&-footer {
			@include minw($grid-breakpoints-xs) {
				padding: 0 20px * .8 25px * .8;
			}
			@include minw($grid-breakpoints-lg) {
				padding: 0 20px 25px;
			}
		}
		&-descr {
			font-size: 10px;
			font-weight: 400;
			line-height: 1.5;
			color: rgba($black, .6);
		}
		&-text {
			font-size: 12px;
			font-weight: 400;
			line-height: 1.5;
			text-transform: capitalize;
		}
		&-choose {
			margin-top: 30px;
			@include minw($grid-breakpoints-xs) {
				margin-top: 50px;
			}
			&-item {
				padding: 20px 15px;
				@include minw($grid-breakpoints-xs) {
					padding: 25px;
				}
			}
			&-title {
				font-size: 18px;
				font-weight: 700;
				margin-bottom: 12px;
			}
			&-text {
				font-size: 16px;
				font-weight: 400;
				&:not(:last-child) {
					margin-bottom: 5px;
				}
			}
			&-descr {
				font-size: 12px;
				font-weight: 400;
				color: rgba($black, .5);
				margin-top: 25px;
			}
		}
	}
</style>
